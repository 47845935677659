@import "reset";
@import "gridjs";

body {
	margin : 0;
}
.admin-dashboard {
	display               : grid;
	grid-template-columns : 75px auto;
	width                 : 100%;
	height                : 100vh;
	overflow              : hidden;
}

.dash-sidebar {
	display       : grid;
	align-content : space-between;
	border-right  : 1px solid oklch(var(--totalform-lightgray));

	.menu {
		display         : grid;
		gap             : 1rem;
		justify-content : center;
		padding         : 1rem 0;
		margin          : 0;
		list-style      : none;

		.menu-item {
			background-color : transparent;
			transition       : background-color 200ms ease-in-out;
			border-radius    : calc(var(--totalform-radius) * 2);
			border           : 2px solid transparent;

			&:hover {
				background-color: oklch(var(--totalform-nearwhite));
			}
			&.active {
				background-color : transparent;
				border-color     : oklch(var(--totalform-nearblack));
			}
		}
		a {
			display          : block;
			width            : 3rem;
			height           : 3rem;
			background-color : oklch(var(--totalform-nearblack));
			mask-size        : 1.5rem;
			mask-repeat      : no-repeat;
			mask-position    : center;
			position         : relative;
			text-indent      : -9999px;
		}
	}
	.home {
		mask-image : var(--icon-logo);
	}
	.schemas {
		mask-image : var(--icon-schema);
	}
	.collections {
		mask-image : var(--icon-collections);
	}
	.utils {
		mask-image : var(--icon-tools);
	}
	.settings {
		mask-image : var(--icon-settings);
	}
	.docs {
		mask-image : var(--icon-docs);
	}
}

.user-profile {
	display         : grid;
	justify-content : center;
	padding         : 1.5rem 0;
	position        : relative;

	img {
		border-radius : 50%;
	}
	button {
		border-radius       : 50%;
		width               : 3rem;
		aspect-ratio        : 1;
		appearance          : none;
		cursor              : pointer;
		border              : none;
		padding             : 0;
		background-color    : transparent;
		background-image    : var(--icon-profile);
		background-repeat   : no-repeat;
		background-position : center;
		background-size     : 2rem;
	}
}

.dash-content {
	flex-grow             : 1;
	display               : grid;
	grid-template-columns : auto;
	align-items           : stretch;

	&:has(.dash-content-sidebar) {
		grid-template-columns : max-content auto;
	}
}


.dash-content-sidebar {
	display            : grid;
	grid-template-rows : min-content 1fr min-content;
	gap                : 1rem;
	align-content      : start;
	border-right       : 1px solid oklch(var(--totalform-lightgray));
	min-width          : 256px;
	height             : 100vh;

	h3 {
		color: oklch(var(--totalform-mediumgray));
	}

	header {
		padding       : 1.5rem 1rem;
		border-bottom : 1px solid oklch(var(--totalform-lightgray));
	}

	nav {
		overflow : auto;
		padding  : 0 1rem;

		.no-schemas {
			padding    : 0.75rem 1rem;
			font-style : italic;
		}

		h3 {
			margin-bottom: 0.5rem;

			&:not(:first-child) {
				margin-top : 1.5rem;
			}
		}

		ul {
			list-style : none;
			padding    : 0;
			margin     : 0;
		}
		li {
			--collection-icon : var(--icon-collections);

			&.schema-auth { --collection-icon : var(--icon-user) }
			&.schema-blog-legacy,
			&.schema-blog { --collection-icon : var(--icon-blog) }
			&.schema-feed { --collection-icon : var(--icon-feed) }
			&.schema-color { --collection-icon : var(--icon-paint-brush) }
			&.schema-date { --collection-icon : var(--icon-date) }
			&.schema-depot { --collection-icon : var(--icon-depot) }
			&.schema-email { --collection-icon : var(--icon-email) }
			&.schema-file { --collection-icon : var(--icon-file) }
			&.schema-gallery { --collection-icon : var(--icon-gallery) }
			&.schema-image { --collection-icon : var(--icon-image) }
			&.schema-number { --collection-icon : var(--icon-number) }
			&.schema-styledtext { --collection-icon : var(--icon-styledtext) }
			&.schema-svg { --collection-icon : var(--icon-svg) }
			&.schema-text { --collection-icon : var(--icon-text) }
			&.schema-toggle { --collection-icon : var(--icon-toggle) }
			&.schema-url { --collection-icon : var(--icon-url) }
			&.logs { --collection-icon : var(--icon-warn) }
			&.image-batcher { --collection-icon : var(--icon-image) }
			&.cache-cleaner { --collection-icon : var(--icon-clean-o) }
			&.project-setup { --collection-icon : var(--icon-flag) }
			&.macro-builder { --collection-icon : var(--icon-build) }
			&.pretty-url-builder { --collection-icon : var(--icon-link) }
			&.server-checker { --collection-icon : var(--icon-health) }
			&.twig-playground { --collection-icon : var(--icon-twig-playground) }
		}
		&.docs li {
			--collection-icon : var(--icon-bookmark);
		}
		a {
			display         : flex;
			align-items     : center;
			text-decoration : none;
			color           : oklch(var(--totalform-text-color));
			padding         : 0.75rem 1rem;
			border-radius   : var(--totalform-radius);
			transition      : background-color 200ms ease-in-out;

			&.active,
			&:hover {
				background-color : oklch(var(--totalform-nearwhite));
			}

			&:before {
				content          : " ";
				display          : inline-block;
				width            : 1rem;
				height           : 1rem;
				background-color : oklch(var(--totalform-text-color));
				mask-image       : var(--collection-icon);
				margin-right     : 0.5rem;
				mask-size        : 0.8rem;
				mask-repeat      : no-repeat;
				mask-position    : center;
			}
		}
	}

	footer {
		padding : 1.5rem 1rem;
	}
}

#schema-links {
	// Hide this legacy blog schema
	.schema-blog-legacy { display: none;}
}

.dash-main-content {
	height             : 100vh;
	overflow           : hidden scroll;
	padding            : 2rem;
	background-color   : oklch(99% 0 0);
	display            : grid;
	gap                : 2rem;
	align-content      : stretch;
	grid-template-rows : max-content auto;

	h1 {
		color  : oklch(var(--totalform-mediumgray));
		margin : 0;

		a {
			text-decoration : none;
			color           : inherit;
		}
		span {
			color : oklch(var(--totalform-darkgray));
		}
	}
	form {
		max-width : 800px;
	}
	header {
		display     : flex;
		gap         : 0.75rem;
		align-items : end;
		position    : relative;

		h1 {
			flex-grow : 1;
		}
	}
}

.doc-content {
	display      : block;
	padding-left : 4rem;

	> *:not(pre) {
		max-width : 65ch;
	}

	h1,h2,h3,h4,h5,h6,p,ul,ol,pre,iframe,.dash-button {
		margin-block-start: 1em;

		&:first-child {
			margin-top: 0;
		}
	}

	h1,h2,h3 {
		margin-block-start: 2em;
	}

	pre {
		background-color : oklch(var(--totalform-nearwhite));
		padding          : 1rem;
		border-radius    : 5px;
		overflow-x       : auto;

		code {
			font-family : 'Operator Mono', 'Source Code Pro', 'Fira Code',  monospace;
		}
	}
	&:has(.docs-iframe) {
		padding : 0;
	}
	.docs-iframe {
		width     : 100%;
		max-width : 100%;
		height    : 100vh;
		border    : none;
	}
}
.dash-content-section {
	display       : grid;
	gap           : 1rem;
	align-content : start;

	p, ul, ol {
		max-width : 65ch;
	}
}
.dash-button {
	display          : inline-block;
	padding          : 0.75rem 1rem;
	border-radius    : var(--totalform-radius);
	background-color : oklab(var(--totalform-nearblack));
	color            : oklab(var(--totalform-white));
	border           : 2px solid oklch(var(--totalform-nearblack));
	text-align       : center;
	text-decoration  : none;
	font-weight      : bold;
	cursor           : pointer;
	width            : max-content;

	&.full {
		display : block;
		width   : 100%;
	}
	&.transparent {
		background-color : transparent;
		color            : oklab(var(--totalform-nearblack));
		border           : 2px solid oklch(var(--totalform-nearblack));
	}
}
.dash-action-dots {
	appearance       : none;
	min-width        : 0.5rem;
	height           : 3rem;
	padding          : 0;
	border           : none;
	background-color : oklch(var(--totalform-gray));
	mask-image       : var(--icon-dots);
	mask-repeat      : no-repeat;
	mask-position    : center;
	mask-size        : 1.75rem;
	cursor           : pointer;
	transition       : background-color 200ms ease-in-out;

	&:hover {
		background-color : oklch(var(--totalform-nearblack));
	}
}
.admin-table {
	display: none;
}
.admin-table-wrapper {
	font-size: 0.9em;

	img, svg {
		display   : block;
		max-width : 3rem;
		height    : auto;
	}
	img {
		border-radius : var(--totalform-radius);
	}

	.gridjs-wrapper, table {
		max-height : calc(100vh - 300px);
	}

	.gridjs-container {
		height                : 100%;
		display               : grid;
		grid-template-rows    : max-content auto max-content 0;
		align-content         : stretch;
	}
	.color-preview {
		height        : 100%;
		text-indent   : -9999px;
		border-radius : var(--totalform-radius);
	}
	.gallery-preview {
		position : relative;
		display  : inline-block;


		.image-count {
			position         : absolute;
			display          : inline-block;
			bottom           : 0;
			right            : 0;
			color            : oklch(var(--totalform-nearblack));
			background-color : oklch(var(--totalform-white));
			border-radius    : var(--totalform-radius) 0 var(--totalform-radius) 0;
			padding          : 0.25rem;
			font-size        : 0.65rem;
			opacity          : 0.85;
		}
	}
}
.server-checker {
	.bundle-error {
		display          : grid;
		gap              : 1rem;
		padding          : 1rem;
		max-width        : 65ch;
		background-color : oklch(var(--totalform-red) / 10%);
		border: 1px solid oklch(var(--totalform-red) / 50%);
		border-radius: var(--totalform-radius);
	}
	.error {
		font-weight    : bold;
		text-transform : uppercase;
		color          : oklch(var(--totalform-red));
	}
	tr:has(.error) {
		background-color : oklch(var(--totalform-red) / 10%) !important;
	}
	tr:has(.warning) {
		background-color : oklch(var(--totalform-warning) / 10%) !important;
	}
	h2:not(:first-child) {
		margin-top : 2rem;
	}
	td.gridjs-td {
		padding     : 0 1rem;
		white-space : wrap;
	}
	th.gridjs-th:first-child {
		max-width : 200px !important;
	}
	.gridjs-wrapper, table {
		max-height : none;
	}
	pre {
		padding: 0.5rem 0;
	}
	.gridjs-tbody .gridjs-tr {
		cursor : inherit;
	}
}
.log-analyzer {
	form,h2:not(:first-child) {
		margin-top : 2rem;
	}
	h2 small {
		font-weight  : 300;
		font-size    : 55%;
		padding-left : 0.5rem;
	}
	td.gridjs-td {
		white-space : wrap;
	}
	th.gridjs-th:first-child {
		max-width : 100px !important;
	}
	.gridjs-wrapper, table {
		max-height : none;
	}
	.gridjs-tbody .gridjs-tr {
		cursor : inherit;
	}
	.log-contents {
		background-color : oklch(var(--totalform-nearwhite));
		padding          : 1.5rem;
		max-height       : 75vh;
		overflow         : auto;
		border: 2px inset oklch(var(--totalform-lightgray) / 50%);
	}
	.no-logs {
		padding          : 1rem;
		max-width        : 65ch;
		background-color : oklch(var(--totalform-success) / 25%);
		border: 1px solid oklch(var(--totalform-success) / 50%);
		border-radius: var(--totalform-radius);
	}
	&:has(.no-logs) {
		h2, form, .log-contents {
			display : none;
		}
	}
}
.admin-table-wrapper,
.dash-content-sidebar {

	input {
		appearance          : none;
		width               : 100%;
		height              : 2.4375rem;
		background-color    : transparent;
		box-shadow          : none;
		border              : none;
		padding             : .5rem;
		margin              : 0;
		border-radius       : var(--totalform-radius);
		font-family         : inherit;
		font-size           : var(--totalform-font-size);
		font-weight         : 400;
		line-height         : 1.5;
		color               : oklch(var(--totalform-text-color));
		transition          : background-color 200ms ease-in-out;
		background-image    : var(--icon-filter);
		background-repeat   : no-repeat;
		background-size     : 16px;
		background-position : 0.5rem center;
		text-indent         : 1.5rem;

		&:hover, &:focus {
			background-color : oklch(var(--totalform-nearwhite));
			outline          : none;
		}
	}
}

#user-actions,
#collection-actions  {
	// eventually this should move to the new CSS Anchor positioning
	position         : absolute;
	inset            : unset;
	margin           : 1rem 0;
	background-color : oklch(var(--totalform-white));
	padding          : 0;
	border-radius    : var(--totalform-radius);
	border           : none;
	box-shadow       : 0 0 10px 2px oklch(var(--totalform-shadow));

	ul {
		list-style : none;
		padding    : 0;
		margin     : 0;
	}
	li {
		--action-icon : var(--icon-logo);

		&.edit { --action-icon : var(--icon-edit) }
		&.profile { --action-icon : var(--icon-user) }
		&.logout { --action-icon : var(--icon-logout) }
		&.factory { --action-icon : var(--icon-factory) }
		&.import { --action-icon : var(--icon-import) }
		&.reindex { --action-icon : var(--icon-refresh) }
	}
	a {
		display         : flex;
		align-items     : center;
		text-decoration : none;
		color           : oklch(var(--totalform-text-color));
		padding         : 0.75rem 1rem;
		// border-radius   : var(--totalform-radius);
		transition      : background-color 200ms ease-in-out;
		min-width       : 200px;

		&.active,
		&:hover {
			background-color : oklch(var(--totalform-nearwhite));
		}

		&:before {
			content          : " ";
			display          : inline-block;
			width            : 1rem;
			height           : 1rem;
			background-color : oklch(var(--totalform-text-color));
			mask-image       : var(--action-icon);
			margin-right     : 0.5rem;
			mask-size        : 0.8rem;
			mask-repeat      : no-repeat;
			mask-position    : center;
		}
	}
}
#collection-actions {
	top   : 5rem;
	right : 2rem;
}
#user-actions {
	bottom : 3rem;
	left   : 4rem;
}
#settings-form {
	margin-top: 2rem;

	.checkbox-field label {
		font-size: 0.9em;
	}
	.cms-button:before {
		mask-image : var(--icon-save);
	}
}

#twig-playground {
	.cms-button:before {
		mask-image : var(--icon-twig-playground);
	}
}

.playground-results {
	display            : grid;
	gap                : 2rem;
	margin-block-start : 2rem;
}